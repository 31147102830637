import React from 'react';
import PropTypes from 'prop-types';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { OmegaVideoPlayer } from 'components/OmegaVideoPlayer';
import Player from 'components/TVE/Player';
import { CoreVideoPlayer } from 'components/CoreVideoPlayer';
import { VideoContainer } from 'components/VideoContainer';
import { VideoControlRack } from 'components/VideoContainer/VideoControlRack';
import { OmegaFeatureFlagSwitch } from 'components/OmegaVideoPlayer/OmegaSwitch';
import { TapToUnmuteButton } from 'components/VideoContainer/TapToUnmuteButton';

import {
  playmakerMetadata as PlaymakerMetadataPropType,
} from 'lib/CustomPropTypes';

/**
 * Function to render playmaker player
 * @param {object} props React Props
 * @param {object} props.playmakerMetadata Playmaker Metadata/config returned
 * @param {boolean} props.isMSNBC Boolean noting if this is for an MSNBC stream
 * @param {boolean} props.isRail Boolean indicating whether or not the item is in a rail
 * @param {boolean} props.bypassAuth Boolean indicated whether or not to bypass authentication
 * @returns {React.ReactElement}
 */
function CoreVideoPlayerEmbed({
  playmakerMetadata, isMSNBC, isRail, bypassAuth,
}) {
  const playmakerRef = React.useRef(null);

  const {
    playmakerPlayBehavior,
    playmakerAutoplayMuted,
    playmakerArt,
    streamId,
  } = playmakerMetadata;

  const cvsdkProps = {
    autoplay: playmakerPlayBehavior === 'autoplay',
    autoplayMuted: playmakerAutoplayMuted,
    clickToPlay: playmakerPlayBehavior === 'clickToPlay',
    isRail,
    playmakerMetadata,
    shouldPauseWhenMutedAndOutOfView: true,
    disableStickyOnMute: true,
    stickyEnabled: true,
  };

  return (
    <>
      <ErrorBoundary>
        {isMSNBC ? (
          <Player
            ref={playmakerRef}
            autoplay={cvsdkProps.autoplay}
            autoplayMuted={cvsdkProps.autoplayMuted}
            clickToPlay={cvsdkProps.clickToPlay}
            isRail={cvsdkProps.isRail}
            playmakerMetadata={cvsdkProps.playmakerMetadata}
            shouldPauseWhenMutedAndOutOfView={cvsdkProps.shouldPauseWhenMutedAndOutOfView}
            disableStickyOnMute={cvsdkProps.disableStickyOnMute}
            stickyEnabled={cvsdkProps.stickyEnabled}
            bypassAuth={bypassAuth}
          />
        ) : (
          <OmegaFeatureFlagSwitch
            ifOmega={<OmegaVideoPlayer stream={streamId} autoplay mutedAutoplay stickyEnabled />}
          >
            <VideoContainer>
              <TapToUnmuteButton />
              <CoreVideoPlayer
                stream={streamId}
                playmakerArt={playmakerArt}
                autoplay
                mutedAutoplay
                stickyEnabled
              />
              <VideoControlRack isRail={isRail} />
            </VideoContainer>
          </OmegaFeatureFlagSwitch>
        )}
      </ErrorBoundary>
    </>
  );
}

CoreVideoPlayerEmbed.propTypes = {
  playmakerMetadata: PlaymakerMetadataPropType.isRequired,
  isMSNBC: PropTypes.bool,
  isRail: PropTypes.bool,
  bypassAuth: PropTypes.bool,
};

CoreVideoPlayerEmbed.defaultProps = {
  isMSNBC: false,
  isRail: false,
  bypassAuth: false,
};

export { CoreVideoPlayerEmbed };
