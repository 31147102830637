import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TeasePicture from 'components/TeasePicture';
import TypeIcon from 'components/TypeIcon';

import AIMS_FLAVORS from 'lib/aimsFlavors';

import styles from './styles.module.scss';

/**
 *
 * @param {object} args
 * @param {boolean} args.displayPlayButton
 * @param {string} args.type
 * @param {TeaseData} args.computedValues
 * @param {boolean} args.isNarrow
 */
export const RelatedContentTeaseMedia = ({
  displayPlayButton, type, computedValues, isNarrow,
}) => {
  const flavors = {
    s: AIMS_FLAVORS.FOCAL_321x214,
    m: AIMS_FLAVORS.FOCAL_321x214,
    l: AIMS_FLAVORS.FOCAL_321x214,
    x: AIMS_FLAVORS.FOCAL_321x214,
  };

  return (
    <div
      className={classNames(
        [styles.media],
        {
          [styles.narrowRelated]: isNarrow,
        },
      )}
    >
      { displayPlayButton && (
        <TypeIcon
          type={type}
          className={classNames(
            [styles.icon],
          )}
        />
      ) }
      <TeasePicture
        responsiveFlavors={flavors}
        computedValues={computedValues}
        className={classNames([styles.picture])}
        hideCredit
      />
    </div>
  );
};

RelatedContentTeaseMedia.propTypes = {
  displayPlayButton: PropTypes.bool,
  type: PropTypes.string.isRequired,
  computedValues: PropTypes.instanceOf(Object).isRequired,
  isNarrow: PropTypes.bool.isRequired,
};

RelatedContentTeaseMedia.defaultProps = {
  displayPlayButton: false,
};
