import { getPlaymakerMetadata } from 'lib/liveVideo';

/**
 * Determines if the tease should override with Playmaker based on various conditions.
 *
 * @param {number} teaseIndex - The index of the tease.
 * @param {string} layoutType - The type of layout for the tease.
 * @param {JSON} metadata - Metadata associated with the tease.
 * @param {number} packageSize - The size of the package.
 * @returns {boolean} True if the tease should override with Playmaker, false otherwise.
 */
const isTeaseCvsdkOverride = (
  teaseIndex,
  layoutType,
  metadata,
  packageSize,
) => {
  if (teaseIndex === 0) {
    const {
      playmakerSlot,
      playmakerToggle,
    } = getPlaymakerMetadata(metadata);

    if (playmakerToggle) {
      return (
        (layoutType === 'feature' && playmakerSlot === 0)
        || (layoutType === 'default'
          && playmakerSlot === 1
          && (packageSize === 5 || packageSize === 6))
        || (layoutType === 'blog' && playmakerSlot === 2 && packageSize === 4)
      );
    }
  }
  return false;
};

export {
  isTeaseCvsdkOverride,
};
