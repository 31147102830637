import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Breakpoints from 'lib/Breakpoints';
import { RouteContext, VerticalContext } from 'lib/ContextTypes';
import {
  content as ContentPropType,
  coverSpreadTease as TeasePropTypes,
  responsiveFlavors as ResponsiveFlavorsPropTypes,
} from 'lib/CustomPropTypes';
import { getVerticalName } from 'lib/verticalType';

import { getPlaymakerMetadata } from 'lib/liveVideo';
import Link from 'components/Link';
import MediaLabel from 'components/MediaLabel';
import TeasePicture from 'components/TeasePicture';
import { Unibrow } from 'components/Unibrow';
import { CvsdkTease } from 'components/CvsdkTease';

import { isTeaseCvsdkOverride } from '../utility';

import './styles.themed.scss';

const block = 'cover-spread-tease';

/**
 * Checks if the content type includes icons.
 *
 * @param {string} contentType - The type of content to check.
 * @returns {boolean} True if the content type includes icons, false otherwise.
 */
const isContentTypeWithIcons = (contentType) => (
  contentType && ['slideshow', 'video'].includes(contentType)
);

/**
 *
 * @param {object} props
 * @param {ContentModule} props.content
 * @param {string} props.headlineSizes
 * @param {number} props.index
 * @param {boolean} props.isRailAdjacent
 * @param {TeaseLayout} props.layout
 * @param {string} props.layoutType
 * @param {JSON} props.packageMetadata
 * @param {number} props.packageSize
 * @param {ResponsiveFlavors} props.responsiveFlavors
 * @param {boolean} props.showInlineIcon
 */
function Tease(props) {
  const vertical = React.useContext(VerticalContext);
  const route = React.useContext(RouteContext);

  const {
    content,
    headlineSizes: fallbackHeadlineSizes,
    index,
    isRailAdjacent,
    layout = {},
    layoutType,
    packageMetadata,
    responsiveFlavors: fallbackSizes,
    showInlineIcon,
    packageSize,
  } = props;

  const {
    showEyebrow = true,
  } = packageMetadata;

  const isCover = route.path !== '/';

  const isCvsdkTease = isTeaseCvsdkOverride(
    index,
    layoutType,
    packageMetadata,
    packageSize,
  );

  const { playmakerRenderMobileCover } = getPlaymakerMetadata(packageMetadata, vertical);

  // Set playmaker display state
  const [canShowPlaymaker, setCanShowPlaymaker] = React.useState(false);

  /**
 * Listens for breakpoint changes and updates the visibility of the playmaker.
 *
 * If the component is a cover and playmaker rendering on mobile cover is disabled,
 * it sets the playmaker visibility based on the current breakpoint.
 * Otherwise, it ensures the playmaker is visible if it was previously hidden.
 */
  const breakpointListener = () => {
    // Playmaker is enabled but configured to not render on the cover
    if (isCover && playmakerRenderMobileCover === false) {
      setCanShowPlaymaker(!Breakpoints.isS());
      // Otherwise show playmaker if hidden
    } else if (!canShowPlaymaker) {
      setCanShowPlaymaker(true);
    }
  };

  React.useEffect(() => {
    Breakpoints.getSmallMQL().addListener(breakpointListener);
    // Call handler right away so state gets updated with initial window size
    breakpointListener();
    // Remove event listener on cleanup
    return () => Breakpoints.getSmallMQL().removeListener(breakpointListener);
  }, []); // Empty array ensures that effect is only run on mount

  const {
    computedValues,
    id,
    item = {},
    type,
  } = content;

  const {
    headline: computedHeadline,
    unibrow,
    url,
  } = computedValues || {};

  const {
    grid,
    headlineSizes,
    headlineTextPositioning = '',
    image: {
      display = '',
      sizes,
    } = {},
    mediaLabel: {
      eyebrowDisplay = '',
      iconBlockDisplay = '',
      iconInlineDisplay = '',
    } = {},
    unibrowDisplay = '',
  } = layout?.[`tease${index}`] ?? {};

  const verticalName = getVerticalName({ item });

  const headline = computedHeadline || (item?.headline?.primary ?? null);

  // Editors set custom flag text (whether live or not), show that text
  const customFlag = content?.metadata?.flag ?? null;

  const showPlaymaker = isCvsdkTease && canShowPlaymaker;

  return (
    <div
      data-testid={block}
      className={classNames(block, grid)}
      data-contentid={id}
    >
      <div className={classNames({
        'no-eyebrow': !showEyebrow,
        [`${block}--${index}`]: (index ?? -1) >= 0,
      })}
      >
        {showPlaymaker ? (
          <CvsdkTease
            icid="tease-coverspread"
            metadata={packageMetadata}
            showDek={false}
            showPlayerControls
            stickyEnabled
            styleOverride={{
              headline: headlineSizes,
            }}
          />
        ) : (
          <>
            <div
              className={classNames(
                `${block}__image`,
                display,
                { [`${block}__image--rail`]: isRailAdjacent },
              )}
              data-testid={`${block}__image`}
            >
              <Link href={url} className={`${block}__image-link`}>
                <TeasePicture
                  className={`${block}__picture`}
                  computedValues={computedValues}
                  lazyload
                  placeholder={false}
                  responsiveFlavors={sizes || fallbackSizes}
                  retina
                  type={type}
                />
              </Link>
              <MediaLabel
                content={content}
                eyebrowClasses={eyebrowDisplay}
                iconBlockClasses={iconBlockDisplay}
              />
            </div>
            <div
              className={`${block}__text-wrapper`}
              data-testid={`${block}__text-wrapper`}
            >
              {(showEyebrow && unibrow && unibrow.text) && (
                <Unibrow
                  unibrow={unibrow}
                  className={classNames(
                    `${block}__unibrow`,
                    unibrowDisplay,
                    {
                      [`${block}__unibrow--opinion`]: verticalName === 'think',
                    },
                  )}
                />
              )}
              <h3
                className={classNames(
                  `${block}__headline`,
                  headlineSizes || fallbackHeadlineSizes,
                  { [`${block}__headline--rail`]: isRailAdjacent },
                )}
                data-testid={`${block}__headline`}
              >
                <Link
                  className={`${block}__headline-link`}
                  to={url}
                >
                  {showInlineIcon && (
                    <MediaLabel
                      content={content}
                      showInlineIcon={showInlineIcon}
                      iconInlineClasses={iconInlineDisplay}
                    />
                  )}
                  {/* TODO: BENTO-15783 extract this flag to sub component */}
                  {customFlag && (
                    <span className={`${block}__headline__flag`}>
                      {customFlag}
                      &nbsp;
                      {'/ '}
                    </span>
                  )}
                  <span
                    className={classNames({
                      [headlineTextPositioning]: isContentTypeWithIcons(type),
                    })}
                    data-testid={`${block}__headline__text`}
                  >
                    {headline}
                  </span>
                </Link>
              </h3>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

Tease.propTypes = {
  content: ContentPropType.isRequired,
  headlineSizes: PropTypes.string,
  index: PropTypes.number,
  isRailAdjacent: PropTypes.bool,
  layout: TeasePropTypes,
  layoutType: PropTypes.string,
  packageMetadata: PropTypes.shape({
    showEyebrow: PropTypes.bool,
  }),
  packageSize: PropTypes.number.isRequired,
  responsiveFlavors: ResponsiveFlavorsPropTypes,
  showInlineIcon: PropTypes.bool,
};

Tease.defaultProps = {
  headlineSizes: '',
  index: null,
  isRailAdjacent: false,
  layout: {},
  layoutType: 'default',
  packageMetadata: {
    showEyebrow: false,
  },
  responsiveFlavors: {},
  showInlineIcon: false,
};

export { Tease };
