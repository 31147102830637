import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { CvsdkTease } from 'components/CvsdkTease';
import Breakpoints from 'lib/Breakpoints';
import getNavbarHeight from 'lib/getNavbarHeight';
import sassVariables from '../../sassVariables.module.scss';


import contentMediaStyles from '../styles.module.scss';

const packageClass = sassVariables?.['package-outer-class'];


/**
 *
 * @param {object} props
 * @returns {React.ReactElement} Live video component for MSP
 */
export default function LiveVideoMedia(props) {
  const { packageMetadata } = props;
  const playmakerRef = useRef(null);
  const playmakerHomeRef = useRef(null);
  const playmakerElement = playmakerRef?.current;
  const playmakerHomeElement = playmakerHomeRef?.current;
  const [playmakerIsHome, setPlaymakerIsHome] = useState(true);
  const navbarHeight = getNavbarHeight(Breakpoints);
  const playmakerTitle = packageMetadata?.playmakerTitle;
  const isWhitespacePlaymakerTitle = playmakerTitle && (playmakerTitle?.trim()?.length === 0);
  // Replacing LiveVideo when LiveVideo Home back in view
  const { ref: inViewRef, inView, entry: inViewEntry } = useInView({
    threshold: 0.5,
    rootMargin: `${-navbarHeight}px 0px 0px 0px`,
  });

  // StickyPIP and InView logic.
  const onStuckChange = useCallback((isStuckVal) => {
    if (playmakerElement) {
      setPlaymakerIsHome(!isStuckVal);
    }
  }, [playmakerElement]);

  useEffect(() => {
    if (inView && playmakerElement) {
      setPlaymakerIsHome(true);
    }
  }, [inView, inViewEntry]);

  useEffect(() => {
    if (!playmakerElement) return;
    if (!playmakerIsHome) {
      const containerEl = playmakerElement.closest(`.${packageClass}`);
      const targetEl = containerEl?.firstChild;
      playmakerElement.classList?.add('msp-pip-stuck');
      targetEl?.before(playmakerElement);
      if (playmakerHomeElement.getBoundingClientRect().y > 0) {
        playmakerElement.classList?.add('bottom');
        playmakerElement.classList?.remove('top');
      } else {
        playmakerElement.classList?.add('top');
        playmakerElement.classList?.remove('bottom');
      }
    } else {
      playmakerElement?.classList?.remove('msp-pip-stuck');
      playmakerElement.classList?.remove('bottom');
      playmakerElement.classList?.remove('top');
      playmakerHomeElement?.before(playmakerElement);
    }
  }, [playmakerIsHome, setPlaymakerIsHome, playmakerElement]);

  const CvsdkTeaseJsx = (
    <CvsdkTease
      icid="storyline-tease"
      metadata={packageMetadata}
      showDek={false}
      showTitle
      showPlayerControls
      isStoryline
      onStuckChange={onStuckChange}
      stickyEnabled
    />
  );

  return (
    <div
      ref={inViewRef}
      className={classNames(contentMediaStyles.liveVideoHomeContainer, 'live-video-home')}
    >
      <div
        ref={playmakerRef}
        className={classNames(
          contentMediaStyles.liveVideoMovingContainer,
          'live-video-moving', {
            'hide-video-label': isWhitespacePlaymakerTitle,
          },
        )}
      >
        { CvsdkTeaseJsx }
      </div>
      <div ref={playmakerHomeRef} />
    </div>
  );
}

LiveVideoMedia.propTypes = {
  packageMetadata: PropTypes.object.isRequired,
};
