import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import AIMS_FLAVORS from 'lib/aimsFlavors';
import Breakpoints from 'lib/Breakpoints';
import {
  package as PackagePropType,
  layoutContext as layoutContextPropType,
} from 'lib/CustomPropTypes';
import { isVideoLive } from 'lib/videoUtils';
import { getPlaymakerMetadata } from 'lib/liveVideo';
import { getDeepLink, isDeepLink } from 'lib/deepLinking';

import SlideshowTeasePicture from 'components/SlideshowTeasePicture';
import PackageTitle from 'components/PackageTitle';
import TeaseCard from 'components/TeaseCard';
import TeasePicture from 'components/TeasePicture';
import VideoPlayer from 'components/VideoPlayer';
import { CvsdkTease } from 'components/CvsdkTease';

import styles from './styles.module.scss';

/**
 *
 * @param {MetadataItem} metadata
 */
const getPlaymakerId = (metadata) => getPlaymakerMetadata(metadata).playmakerId;

class StraightUp extends React.Component {
  static propTypes = {
    content: PackagePropType.isRequired,
    isRailAdjacent: PropTypes.bool,
    alternateTitleFormat: PropTypes.bool,
    pkgClassName: PropTypes.string,
  };

  static childContextTypes = {
    setStickyVideoFlag: PropTypes.func,
  };

  static contextTypes = {
    ...layoutContextPropType,
  };

  static defaultProps = {
    isRailAdjacent: false,
    pkgClassName: 'pkg straight-up',
    alternateTitleFormat: false,
  };

  /**
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);
    const { content: { metadata } } = props;

    this.state = {
      isLive: false,
      hasStickyVideo: false,
      hasClickedToPlay: false,
      playmakerId: getPlaymakerId(metadata),
    };
  }

  /**
   *
   * @param {object} props
   * @param {object} props.content
   * @param {MetadataItem} props.content.metadata
   * @param {StateEnum} state
   */
  static getDerivedStateFromProps(props, state) {
    const { content: { metadata } } = props;
    const { playmakerId: prevPlaymakerId } = state;
    const playmakerId = getPlaymakerId(metadata);

    // Reset state if changed to/from Playmaker implementation or PID changed
    if (prevPlaymakerId !== playmakerId) {
      return {
        isLive: false,
        hasStickyVideo: false,
        hasClickedToPlay: false,
        playmakerId,
      };
    }
    return null;
  }

  /**
   *
   * @param {boolean} hasStickyVideo
   */
  setStickyVideoFlag = (hasStickyVideo) => {
    this.setState({ hasStickyVideo });
  };

  /**
   * Provides the context for child components setting the sticky video flag.
   */
  getChildContext = () => ({
    setStickyVideoFlag: this.setStickyVideoFlag,
  });

  /**
   * Retrieves the picture component based on the content type.
   */
  getPicture = () => {
    const { content: { items: [item] } } = this.props;
    const { computedValues, type } = item;

    if (type === 'slideshow') {
      // slides is now items[0].item.slides -- make sure this is correct
      const { item: { slides } } = item;

      return (
        <SlideshowTeasePicture
          slides={slides}
          sizes={{
            s: 360, m: 460, l: 260, xl: 380,
          }}
          rectangle
        />
      );
    }
    return (
      <TeasePicture
        responsiveFlavors={{
          s: AIMS_FLAVORS.FOCAL_440x220,
          m: AIMS_FLAVORS.FOCAL_720x360,
          l: AIMS_FLAVORS.FOCAL_520x260,
          xl: AIMS_FLAVORS.FOCAL_720x360,
        }}
        computedValues={computedValues}
        type={type}
      />
    );
  };

  /**
   * Retrives the video component if the conditions are met.
   */
  getVideo = () => {
    const { hasClickedToPlay, hasStickyVideo } = this.state;
    const { content: { items: [item] } } = this.props;

    if (item.type !== 'video' || !hasClickedToPlay) {
      return null;
    }

    return (
      <div
        className={classNames(
          styles.videoContainer,
          {
            [styles.isStuck]: hasStickyVideo,
          },
        )}
      >
        <div className={styles.videoResize}>
          <VideoPlayer autoPlay manualPlay video={item.item} />
        </div>
      </div>
    );
  };

  /**
   * Retrieves the appropriate tease component based on the playmaker toggle
   */
  getTease = () => {
    const { content: { metadata: { playmakerToggle } = {} } } = this.props;
    if (playmakerToggle === true) {
      return this.getCvsdkTease();
    }
    return this.getNormalTease();
  };

  /**
   * Retrives the normal tease component
   */
  getNormalTease = () => {
    const {
      content: {
        items,
        metadata: packageMetadata,
        type,
      },
      isRailAdjacent,
      pkgClassName,
    } = this.props;

    const [item] = items;

    if (this.isLive()) {
      packageMetadata.showAuthor = true;
      packageMetadata.showTimestamp = true;
    }

    const {
      isFluidWidthPage,
    } = this.context;

    return item && (
      <>
        <TeaseCard
          additionalClasses={{
            content: styles.teaseCardContent,
            dek: styles.teaseCardDek,
            info: styles.teaseCardInfo,
            liveText: styles.teaseCardLiveText,
            picture: styles.teaseCardPicture,
            pictureLink: styles.teaseCardPictureLink,
            title: styles.teaseCardTitle,
          }}
          content={item}
          imageClick={this.handleClick}
          isRailAdjacent={isRailAdjacent}
          pkgClassName={pkgClassName}
          pkgType={type}
          showDek
          showFeaturedAuthor
          showUnibrowInset={!isFluidWidthPage}
          showLive={this.isLive()}
          typeIconSize="medium"
          {...item.metadata}
          {...packageMetadata}
        >
          {this.getPicture()}
        </TeaseCard>
        {this.getVideo()}
      </>
    );
  };

  /**
   * Retrives the CVSDK tease component
   */
  getCvsdkTease = () => {
    const {
      content: { metadata, items: [item] },
      isRailAdjacent,
    } = this.props;

    const { computedValues: { unibrow } = {} } = item;

    const {
      isFluidWidthPage,
    } = this.context;

    const styleOverride = {
      gridOuter: 'layout-grid-container gutter-collapse',
      gridCol1: 'layout-grid-item grid-col-12 grid-col-7-l grid-col-8-xl',
      gridCol2: 'layout-grid-item grid-col-12 grid-col-5-l grid-col-4-xl ph4 ph0-m pv6-l pr6-l',
      headline: !isFluidWidthPage ? 'f7 f8-m f7-l f8-xl' : '',
    };
    if (isRailAdjacent) {
      styleOverride.gridCol1 = 'layout-grid-item grid-col-12 grid-col-7-l';
      styleOverride.gridCol2 = 'layout-grid-item grid-col-12 grid-col-5-l ph4 ph0-m pv4-l pr4-l';
    }
    if (isFluidWidthPage) {
      styleOverride.gridOuter = 'cvsdk-tease__outer';
      styleOverride.gridCol1 = 'cvsdk-tease__player';
      styleOverride.gridCol2 = 'cvsdk-tease__info';
    }
    return (
      <CvsdkTease
        handleStatus={this.handleStatus}
        icid="tease-straightup"
        metadata={metadata}
        styleOverride={styleOverride}
        unibrow={unibrow}
      />
    );
  };

  /**
   *
   * @param {Event} e
   */
  handleClick = (e) => {
    const { content: { items: [item] } } = this.props;
    const deepLink = isDeepLink(item);

    if (Breakpoints.isSorM()) {
      return;
    }

    if (!deepLink && item.type === 'video') {
      e.preventDefault();
      this.setState({ hasClickedToPlay: true });
    }
  };

  /**
   *
   * @param {string} status
   * @param {boolean} isLive
   */
  handleStatus = (status, isLive) => {
    this.setState({ isLive });
  };

  /**
   * Checks if the content is live
   */
  isLive = () => {
    const { isLive } = this.state;
    if (isLive) {
      return true;
    }
    const { content: { items: [item] } } = this.props;
    return isVideoLive(item);
  };

  /**
   * Renders the StraightUp Component
   */
  render() {
    const {
      content: {
        id,
        items: [item],
        type,
        metadata,
      },
      isRailAdjacent,
      pkgClassName,
      alternateTitleFormat,
    } = this.props;

    const deepLink = getDeepLink(item, type);

    return (
      <section
        className={classNames(
          pkgClassName,
          styles.straightUp,
          {
            [styles.live]: this.isLive(),
            [styles.deepLink]: deepLink,
            [styles.isRailAdjacent]: isRailAdjacent,
          },
        )}
        data-packageid={id}
      >
        {metadata ? (
          <PackageTitle
            metadata={metadata}
            alternateDisplayFormat={alternateTitleFormat}
          />
        ) : null}
        <div className={styles.inner}>
          {this.getTease()}
        </div>
      </section>
    );
  }
}

export { StraightUp };
