import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import classNames from 'classnames';
import getConfig from 'next/config';

import {
  package as PackagePropType,
} from 'lib/CustomPropTypes';
import { TVE_CANONICAL_ROUTE } from 'lib/tve';

import { loadScheduleAction, showProviderSelectAction } from 'redux/modules/tve';
import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';

import ButtonHoverAnimation from 'components/ButtonHoverAnimation';
import IntObserver from 'components/IntObserver';
import Player from 'components/TVE/Player';
import TempPass from 'components/TVE/TempPass';
import WithTVEAuthProvider from 'components/TVE/AuthProvider';
import { ERROR_TYPES } from 'components/TVE/Slate';

import Countdown from './Countdown';
import { PackageTitle } from './PackageTitle';
import { PlayerInfo } from './PlayerInfo';
import { Schedule } from './Schedule';
import { SignIn } from './SignIn';

import styles from './styles.module.scss';

class LiveVideoPromo extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool,
    content: PackagePropType.isRequired,
    epgCurrent: PropTypes.shape({
      program: PropTypes.shape({
        title: PropTypes.string,
      }),
      thumbMd: PropTypes.string,
    }),
    hasTempPass: PropTypes.bool,
    epgUpcoming: PropTypes.arrayOf(
      PropTypes.shape(schedulePropType),
    ),
    loadSchedule: PropTypes.func,
    providerSelectVisible: PropTypes.bool,
    showProviderSelect: PropTypes.func,
    vertical: PropTypes.string,
    videoError: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  };

  static defaultProps = {
    authenticated: null,
    epgCurrent: {},
    epgUpcoming: [],
    hasTempPass: false,
    loadSchedule: Function.prototype,
    providerSelectVisible: false,
    showProviderSelect: Function.prototype,
    vertical: null,
    videoError: false,
  };

  /**
 * Constructor for the component.
 *
 * @param {object} props
 * @param {boolean} props.authenticated - Indicates if the user is authenticated.
 * @param {object} props.content - The content package, required.
 * @param {object} props.epgCurrent - The current EPG (Electronic Program Guide) information.
 * @param {object} props.epgCurrent.program - The current program details.
 * @param {string} props.epgCurrent.program.title - The title of the current program.
 * @param {string} props.epgCurrent.thumbMd - The medium thumbnail for the current program.
 * @param {boolean} props.hasTempPass - Indicates if the user has a temporary pass.
 * @param {Array} props.epgUpcoming - The upcoming EPG schedule.
 * @param {Function} props.loadSchedule - Function to load the schedule.
 * @param {boolean} props.providerSelectVisible - Indicates if the provider select is visible.
 * @param {Function} props.showProviderSelect - Function to show the provider select.
 * @param {(string|boolean)} props.videoError - The video error message or a boolean indicating an error.
 * @param {VerticalType} props.vertical - The vertical identifier.
 */
  constructor(props) {
    super(props);
    this.props = props; // this line allows intellisense to figure out the shape of this.props later in the file
    this.iframeRef = React.createRef();
    this.state = {
      hasEnteredViewport: false,
    };
  }

  /**
   * When LiveVideoPromo is first rendered, it will trigger the loadSchedule function with the vertical prop value.
   */
  componentDidMount() {
    const { loadSchedule, vertical } = this.props;
    loadSchedule(vertical);
  }

  /**
 * Callback function for the IntersectionObserver.
 *
 * @param {IntersectionObserverEntry[]} entries - An array of IntersectionObserverEntry objects.
 */
  handleObserverCallback = ([entry]) => {
    const { isIntersecting } = entry;
    const { hasEnteredViewport } = this.state;
    if (isIntersecting && !hasEnteredViewport) {
      this.setState({ hasEnteredViewport: true });
    }
  };

  /**
 * Returns an animated player component.
 *
 * @param {string} liveVideoUrl - The URL of the live video stream.
 * @param {string} icid - The unique identifier for the video instance.
 */
  getAnimatedPlayer = (liveVideoUrl, icid) => {
    const {
      epgCurrent,
      showProviderSelect,
    } = this.props;

    const videoSource = epgCurrent?.thumbMd;

    if (!videoSource) return null;

    return (
      <div className={classNames(styles.player, styles.playerAnimated)}>
        <a href={liveVideoUrl} className={styles.link} data-icid={icid}>
          <video autoPlay loop playsInline muted>
            <source src={videoSource} type="video/mp4" />
          </video>
        </a>
        <button
          aria-label="lock icon"
          type="button"
          onClick={showProviderSelect}
          className={classNames(styles.lock, 'icon icon-lock')}
        />
      </div>
    );
  };

  /**
  * Returns a live video player component.
  *
  * @param {string} liveVideoUrl - The URL of the live video stream.
  * @param {string} icid - The unique identifier for the video instance.
  */
  getLiveVideoPlayer = (liveVideoUrl, icid) => {
    const { publicRuntimeConfig: { DISABLE_AUTH_TVE_MSNBC } } = getConfig();

    return (
      <div className={classNames(styles.player, styles.playerLive)}>
        <a href={liveVideoUrl} className={styles.link} data-icid={icid}>
          <Player
            autoplayMuted
            bypassAuth={DISABLE_AUTH_TVE_MSNBC}
            externalControlbar={1}
          />
        </a>
      </div>
    );
  };

  /**
   * renders the LiveVideoPromo component.
   */
  render() {
    const {
      authenticated,
      hasTempPass,
      content: {
        id,
        metadata: {
          playmakerButtonLink,
          playmakerButtonText,
          title: packageTitle,
        },
      },
      epgCurrent,
      epgUpcoming,
      providerSelectVisible,
      showProviderSelect,
      videoError,
    } = this.props;

    const { publicRuntimeConfig: { DISABLE_AUTH_TVE_MSNBC } } = getConfig();

    const { hasEnteredViewport } = this.state;
    const showLiveVideo = authenticated || hasTempPass;
    const currentProgramTitle = epgCurrent?.program?.title;
    const liveVideoUrl = playmakerButtonLink || TVE_CANONICAL_ROUTE;
    const icid = 'tve-rail-tease';
    const upcomingShows = epgUpcoming && epgUpcoming.slice(0, 3);

    return (
      <IntObserver
        callback={this.handleObserverCallback}
        threshold={0.25}
      >
        {
          hasEnteredViewport && !DISABLE_AUTH_TVE_MSNBC
            && (
              <>
                <WithTVEAuthProvider iframeRef={this.iframeRef} />
                {videoError !== ERROR_TYPES.BROWSER && (
                  <TempPass
                    checkTempPass={authenticated === false && hasTempPass === false}
                  />
                )}
              </>
            )
        }
        <section
          className={styles.liveVideoPromo}
          data-packageid={id}
          data-testid="live-video-promo"
        >
          <div
            className="bacon__header--top-border"
            data-testid="bacon__header__top-border"
          />
          <div className={styles.heading} data-testid="live-video-promo__heading">
            <PackageTitle
              text={packageTitle || i18next.t('WATCH MSNBC TV')}
              url={liveVideoUrl}
              icid={icid}
            />
            {
              hasEnteredViewport
              && !DISABLE_AUTH_TVE_MSNBC
              && (hasTempPass || (!hasTempPass && !authenticated))
              && (
                <div className={styles.countDownSignIn} data-testid="live-video-promo__countdown">
                  {
                    hasTempPass && <Countdown />
                  }
                  {
                    !hasTempPass && !authenticated && (
                      <SignIn
                        providerSelectVisible={providerSelectVisible}
                        showProviderSelect={showProviderSelect}
                      />
                    )
                  }
                </div>
              )
            }
          </div>
          <div className={styles.content}>
            <div>
              {
                showLiveVideo
                  ? this.getLiveVideoPlayer(liveVideoUrl, icid)
                  : this.getAnimatedPlayer(liveVideoUrl, icid)
              }
              <PlayerInfo
                flagText={i18next.t('LIVE')}
                title={currentProgramTitle}
                url={liveVideoUrl}
                icid={icid}
              />
            </div>
            <div>
              <Schedule items={upcomingShows} />
              <ButtonHoverAnimation
                additionalClasses={styles.cta}
                title={playmakerButtonText || i18next.t('WATCH NOW')}
                type="link"
                url={liveVideoUrl}
                icid={icid}
              />
            </div>
          </div>
        </section>
      </IntObserver>
    );
  }
}

/**
 * Maps the state to props for the component.
 *
 * @param {object} args - The root state object.
 * @param {object} args.shared - The shared state object.
 * @param {tveMp4} args.tve - The TVE (TV Everywhere) state object.
 * @param {Video} args.video - The video state object.
 */
const mapStateToProps = ({ shared, tve, video }) => ({
  authenticated: tve.authenticated,
  epgCurrent: tve.epgCurrent,
  epgUpcoming: tve.epgUpcoming,
  hasTempPass: tve.hasTempPass,
  providerSelectVisible: tve.providerSelectVisible,
  vertical: shared.vertical,
  videoError: video.error,
});

/**
 * Maps dispatch actions to props for the component.
 *
 * @param {Function} dispatch - The Redux dispatch function.
 */
const mapActionsToProps = (dispatch) => ({
  /**
   * Dispatches the loadScheduleAction with the given vertical.
   *
   * @param {string} vertical - The vertical identifier.
   */
  loadSchedule: (vertical) => dispatch(loadScheduleAction(vertical)),
  /**
   * Dispatches the showProviderSelectAction.
   */
  showProviderSelect: () => dispatch(showProviderSelectAction()),
});

export default connect(mapStateToProps, mapActionsToProps)(LiveVideoPromo);
